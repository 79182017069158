$day-buttons-padding: 1.7rem;
$day-buttons-height: 6rem + 2 * $day-buttons-padding;
$week-navigator-height: 2.2rem;

.fc-toolbar {
    align-items: flex-start;
    .timetable__calendar--day-view & {
        margin-bottom: $day-buttons-height;
    }
}

.timetable__day-buttons {
    position: relative;
    top: $week-navigator-height;
    max-width: 50rem;
    padding: $day-buttons-padding 0;
    margin: 0 auto -1 * $day-buttons-height;
}

.fc-center {
    /* isolation */
    opacity: 0.9999999;
    display: flex;
    position: relative;
    width: 100%;
    justify-content: center;
    @media screen and (max-width: 18.5rem) {        
        bottom:2rem;  
    }  
}

.fc-center h2 {
    display: inline-block;
    font-size: 1.5rem;
    color: var(--dark-text-color);
    font-weight: 400;
    vertical-align: middle;
    text-align: center;
    flex: 0 1 13em;
}

%navbutton {
    background: url(styles/chevron-right.svg) center no-repeat;
    width: 2.5rem;
    height: 2.5rem;
    flex: none;
    border: none;
    > * {
        display: none;
    }
    &:hover,
    &:focus,
    &:active {
        .timetable.timetable & {
            background-color: transparent;
            color: unset;
            box-shadow: none;
            outline: none;
        }
    }
    &:disabled {
        visibility: hidden;
    }
}

.fc-prev-button {
    @extend %navbutton;
    transform: rotate(0.5turn);
}

.fc-next-button {
    @extend %navbutton;
}

.week {
    display: flex;
    justify-content: space-between;
    flex: 1 0 100%;
}

.week__day {
    display: inline-block;

    &:not(:last-child) {
        margin-right: -1rem;
    }
}
