.footer {
    display: flex;
    position: relative;
    width: 100%;
    justify-content: center;
    align-items: center;
    box-shadow: 0 -0.2rem 0.4rem 0 var(--box-shadow-color);
    background: var(--container-bg-color);
}

.footer--embedded {
    box-shadow: none;
    justify-content: flex-start;
    margin-top: -4rem;
}

.footer__logo {
    display: flex;
    font-size: 4.4rem;
    margin: 1.7rem;
}

.footer--embedded .footer__logo {
    font-size: 3rem;
    color: var(--primary-select-color);
}

.footer--embedded .footer__logo path {
    fill: var(--primary-select-color);
}

.barion-logo {
    display: flex;
    justify-content: center;
    padding-bottom: 1em;
}

.barion-logo__image {
    width: 25em;
}