$theme-colors: (
  "primary": #fff
);

// Make breakpoints inclusive, like our media queries
$grid-breakpoints: (
  xs: 0,
  sm: 577px,
  md: 769px,
  lg: 993px,
  xl: 1201px
);

@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";
@import "node_modules/bootstrap/scss/transitions";
@import "node_modules/bootstrap/scss/navbar";

.navbar {
  padding: 0 1.3rem;
}

.navbar a {
  text-decoration: none;
}

.navbar a:hover {
  color: initial;
}