/* Common styles */
@import "./styles/fonts.css";
@import "./styles/vars.css";

/* Form components styles */
@import "./components/form-components/TextInput/TextInput.css";
@import "./components/form-components/Checkbox/Checkbox.css";
@import "./components/form-components/PrimaryButton/PrimaryButton.css";
@import "./components/form-components/SecondaryButton/SecondaryButton.css";
@import "./components/form-components/SegmentedControl/SegmentedControl.css";
@import "./components/form-components/PhoneInput/PhoneInput.css";
@import "./components/form-components/ValidationMessage/ValidationMessage.css";
@import "./components/form-components/UserForms.css";
@import "./components/form-components/Radio/Radio.css";
@import "./components/form-components/Select/Select.css";

/* Components styles */
@import "./App.css";
@import "./components/icons/icons.css";
@import "./components/ErrorBox/ErrorBox.css";
@import "./components/Header/Header.css";
@import "./components/Popup/Popup.css";
@import "./components/Login/Login.css";
@import "./components/ContactInfo/ContactInfo.css";
@import "./components/HeroSection/HeroSection.css";
@import "./components/AboutUs/AboutUs.css";
@import "./components/ServiceDescription/ServiceDescription.css";
@import "./components/Confirmation/Confirmation.css";
@import "./components/CalendarDescription/CalendarDescription.css";
@import "./components/Footer/Footer.css";
@import "./components/Image/Image.css";
@import "./components/Reservations/Paginator.css";
@import "./components/Reservations/Filter.css";
@import "./components/Reservations/Reservations.css";
@import "./components/Header/ProfileMenu/ProfileMenu.css";
@import "./components/DataPolicy/DataPolicy.css";
@import "./components/Password/Password.css";
@import "./components/UserDetails/PersonalDetails/PersonalDetails.css";
@import "./components/UserDetails/InvoiceDetails/UserInvoiceDetails.css";
@import "./components/UserDetails/UserDetails.css";
@import "./components/ReadonlyInfo/ReadonlyInfo.scss";
@import "./components/SuccessMessage/SuccessMessage.scss";
@import "./components/Section.scss";
@import "./components/Home/Home.css";
@import "./components/CookieConsentFooter/CookieConsentFooter.css";

/* Modules styles */

/* Booking Flow module */
@import "./components/BookingFlow/BookingFlow.css";
@import "./components/BookingFlow/Stepper/Stepper.css";
@import "./components/BookingFlow/Step/Step.css";
@import "./components/BookingFlow/StepNumber/StepNumber.css";
@import "./components/BookingFlow/StepValue/StepValue.css";

/* Calendar Select module */
@import "./components/BookingFlow/modules/CalendarSelect/CalendarSelect.css";
@import "./components/BookingFlow/modules/CalendarSelect/CalendarItem/CalendarItem.css";

/* Service Select module */
@import "./components/BookingFlow/modules/ServiceSelect/ServiceSelect.css";
@import "./components/BookingFlow/modules/ServiceSelect/ServiceItem/ServiceItem.css";

/* DateTime Select module */
@import "./components/BookingFlow/modules/DateTimeSelect/DateTimeSelect.css";
@import "./components/BookingFlow/modules/DateTimeSelect/DatePicker/DatePicker.css";
@import "./components/BookingFlow/modules/DateTimeSelect/PersonCount/PersonCount.css";
@import "./components/BookingFlow/modules/DateTimeSelect/AppointmentCalendar.scss";

/* Customer Data module */
@import "./components/BookingFlow/modules/CustomerData/CustomerData.css";

/* Reservation End */
@import "./components/BookingFlow/ReservationEnd/ReservationEnd.css";
@import "./components/BookingFlow/ReservationEnd/PaymentSelect/PaymentSelect.css";

/* Reservation Confirm module */
@import "./components/BookingFlow/ReservationConfirm/ReservationConfirm.css";

/* Payment Processing */
@import "./components/Payment/PaymentProcessing/PaymentProcessing.css";

/* Payment Process Pending */
@import "./components/Payment/PaymentProcessPending/PaymentProcessPending.css";

/* Unsuccessful Payment */
@import "./components/Payment/UnsuccessfulPayment/UnsuccessfulPayment.css";

/* Reservation Modification module */
@import "./components/ModifyReservation/ModifyReservation.css";

/* FullCalendar */
@import "~@fullcalendar/core/main.css";
@import "~@fullcalendar/daygrid/main.css";
@import "~@fullcalendar/timegrid/main.css";
@import "~@fullcalendar/timeline/main.css";
@import "~@fullcalendar/resource-timeline/main.css";

/* TimeTableSelect module */
@import "./components/BookingFlow/modules/TimeTableSelect/TimeTableSelect.scss";

/* HeaderMenu module */
@import "./components/Header/Menu/HeaderMenu.scss";

/* OverviewCalendar module */
@import "./components/OverviewCalendar/OverviewCalendar.scss";

@import "./components/Placeholder/Placeholder.css";

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    font-size: 68.75%;
}

body,
html {
    width: 100%;
    height: 100%;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
}

body {
    margin: 0;
    padding: 0;
    overflow: auto;
    overflow-x: hidden;
    font-family: var(--font-family);
    font-size: var(--text-default-size);
    color: var(--dark-text-color);
}

*:focus {
    outline: var(--outline);
    outline-offset: var(--outline-offset);
}