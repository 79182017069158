.App__section {
    margin: 3rem 0;
}

.section {
    width: 100%;

    &__sub-heading {
        padding: 0 0 1rem;
        font-size: var(--text-heading-size);
        font-weight: 700;
        border-bottom: var(--spacer-grey-color) 0.1rem solid;
        padding-bottom: 0.5em;
        margin-bottom: 1rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}
