.App__page-placeholder {
    margin-top: 5rem;
    width: 100%;
}

.placeholder {
    width: 100%
}

.placeholder__line {
    width: 100%;
}

.placeholder__line--last {
    width: 20%;
}

.text-skeleton {
    display: inline-block;
    font-size: 1em;
    height: 1.4ex;
    overflow: hidden;
}

.bone {
    background: var(--placeholder-light-color);
    cursor: initial;
    animation: 1s infinite alternate pulse 
}

@keyframes pulse {
    from {
        background-color: var(--placeholder-dark-color)
    }

    to {
        background-color: var(--placeholder-light-color)
    }
}

.bone__marrow {
    visibility: hidden;
}