.service-description {
    padding: 0 2.5rem 3rem 4rem;
    min-height: 0;
}

.service-description-scroll {
    max-height: calc(80vh - 15rem);
    min-height: 15rem;
    padding-right: 2rem;
}

.service-description-note {
    font-weight: 700;
}

.service-description__details,
.service-description-note--schedule {
    color: var(--light-text-color);
    font-size: var(--text-default-size);
    font-weight: 700;
}

.service-description__details,
.service-description-note--schedule {
    text-align: center;
}

.service-description__detail:not(:only-child):not(:first-child):before {
    content:" ";
    width: 0;
    height: 1em;
    border-right: solid 0.1em var(--light-text-color);
    filter: opacity(0.5);
    display: inline-block;
    margin: 0 1.33em;
    position: relative;
    top: 0.06em;
}

.service-description-note,
.service-description__body {
    padding: 1rem 0;
}

.service-description__details,
.service-description-note--schedule {
    padding: 0;
    padding-bottom: 1rem;
}

.service-description__body {
    width: 100%;
}

.service-description__paragraph {
    margin-bottom: 1.2em;
}

.service-description__buttons {
    margin-top: 3rem;
    flex: 0 0 auto;
    display: flex;
    width: 100%;
    justify-content: center;
    text-transform: capitalize;
    color: var(--light-text-color);
}

.service-description__button {
    flex: 0 1 15rem;
    margin: 0 .5rem;
}

.service-description__cancel{
    margin-right: 0;
}

.service-description__person-count {
    display: flex;
    align-items: center;
    align-self: center;
}

.service-description__service-logo {
    display: block;
    max-height: 20rem;
    max-width: 20rem;
    margin: 1rem auto;
}

.service-description__not-available {
    padding: 2rem 0 0;
    width: 100%;
    text-align: center;
    color: var(--dangerous-color);
}

.service-description .rcs-inner-container {
    max-height: calc(80vh - 15rem);
}

.service-description__price {
    padding: .5rem;
}

@media screen and (max-width: 37.5rem) {
    .service-description {
        padding: 0 1.5rem 2rem 2rem;
    }

    .service-description-scroll {
        padding-right: 1.5rem;
    }

    .service-description__buttons {
        margin-top: 2rem;
    }
}
