@import '~react-custom-scroll/dist/customScroll.css';

.App {
    position: relative;
    height: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    margin-right: calc(-100vw + 100%);
    background-color: var(--site-bg-color);
}

.App__container {
    position: relative;
    margin: 0 auto;
    max-width: 1000px;
    width: 100%;
    height: 100%;
    background-color: var(--container-bg-color);
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
}

.App--embedded .App__container {
    min-height: unset;
}

.App__container-inner {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5rem 3rem;
    margin: 0 auto;
}

.App--embedded .App__container-inner {
    padding: 1rem;
}

.App__user-button {
    display: block;
    margin: 1rem;
    align-self: flex-end;
    line-height: 0;
}

.App__form {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.App__call-to-action {
    padding-left: 2em;
    padding-right: 2em;
    margin: 0 auto;
    align-self: center;
    font-size: var(--text-heading-size);
}

.link {
    color: var(--primary-select-color);
    cursor: pointer;
    -webkit-appearance: none;
    border: none;
    background: none;
    font-family: inherit;
    display: inline-block;
    text-overflow: ellipsis;
    max-width: 100%;
    overflow: hidden;
    vertical-align: text-bottom;
}

.p--light-text {
    color: var(--light-text-color);
}

.App__loading-bar {
    display: inline-block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: var(--popup-level);
}

.availability-warning {
    font-size: var(--text-default-size);
}

.availability-warning--color-red {
    color: var(--dangerous-color);
}

.availability-warning--app-level {
    font-size: var(--text-title-size);
    padding: 3rem;
}

.availability-warning--center {
    text-align: center;
}

.rcs-custom-scroll {
    overflow: hidden;
}

.rcs-custom-scroll .rcs-inner-handle {
    background-color: var(--primary-button-bg-color);
    width: 0.4rem;
    margin-top: 0;
}

.rcs-custom-scroll .rcs-custom-scrollbar {
    opacity: 1;
    transition: none;
}

body,
.fc-scroller {
    scrollbar-width: thin;
    scrollbar-color: var(--primary-button-bg-color) var(--transparent-color);
}

body::-webkit-scrollbar,
.fc-scroller::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
}

body::-webkit-scrollbar-track,
.fc-scroller::-webkit-scrollbar-track {
    opacity: 1;
    transition: none;
}

body::-webkit-scrollbar-thumb,
.fc-scroller::-webkit-scrollbar-thumb {
    background-color: var(--primary-button-bg-color);
    border-radius: 0.4rem;
    width: 0.4rem;
    margin-top: 0;
}

@media screen and (max-width: 48rem) {
    .App__container-inner {
        width: 100%;
        padding: 3rem 1.5rem 2rem 1rem;
    }

    .App__fixed-button {
        width: 100%;
    }
}