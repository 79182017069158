/*! autoprefixer grid: autoplace */
.appointment-calendar {
    &__text {
        top: 2.4rem;
        position: relative;
        text-align: left;
        left: 3.5rem;
        width: 15rem;
        z-index: 5;
        @media screen and (max-width: 48rem){
            top: 2.7rem;
            left: 0;
        }
    }
    
    ol, &__slot {
        color: white;
        position: absolute;
        top: 0.1rem;
        bottom: 0rem;
        right: -0.1rem;
        left: -0.1rem;
        list-style-type: none;
        display: grid;
    }

    &__slot[data-cells="1"] {
        grid-template-rows: 1fr;
        grid-template-columns: 1fr;
        font-size: 2rem;
    }

    &__slot[data-cells="2"] {
        grid-template-rows: 1fr 1fr;
        grid-template-columns: 1fr;
    }

    &__slot[data-cells="3"] {
        grid-template-rows: 1fr;
        grid-template-columns: 1fr 1fr 1fr;
    }

    &__slot[data-cells="4"] {
        grid-template-rows: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
        font-size: 1.5rem;
    }

    &__slot[data-cells="6"] {
        grid-template-rows: 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr;
    }

    &__cell {
        background: var(--primary-button-bg-color);
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0.05rem;
    }

    &__cell-button {
        border: none;
        background: none;
        appearance: none;
        font-family: inherit;
        color: inherit;
        cursor: pointer;
        display: block;
        width: 100%;
        height: 100%;
        &:hover {
            background: var(--primary-button-bg-hover-color);
        }
    }

    &__cell--empty {
        visibility: hidden;
    }

    &__cell--interactive {
        cursor: pointer;
    }

    .fc-event {
        background-color: transparent;
    }

    .fc-timeGridDay-view,
    .fc-dayGridDay-view,
    .fc-list-view {
        &__slot {
            display: flex;
            justify-content: stretch;
        }

        &__cell {
            flex: 1 0 0;
        }
    }

    .fc-day-grid-event {
        margin-top: 0;
        margin-bottom: 0;
        min-height: 4em;
    }

    .fc-list-view {
        &__slot {
            position: static;
        }
    }
    
    .timetable__timezone-offset{
        @media screen and (max-width: 48rem) {          
            top: 5.5rem; 
        }
    } 
}

