.stepper {
    position: relative;
    justify-content: space-between;
    background-color: var(--steps-bg-color);
}

.stepper__jump-to-pending--top {
    position: absolute;
    top: .5rem;
    right: 2rem;
}

.stepper__jump-to-pending--bottom {
    color: var(--light-text-color);
}

.stepper__jump-to-pending-button {
    margin-left: 1em;
}

@media screen and (max-width: 48rem) {
    .stepper__jump-to-pending--top {
        display: none;
    }
}