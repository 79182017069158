.home__section {
    margin-bottom: 5rem;
    width: 100%;
    display: flex;
    flex-direction: column;
}

@media screen and (max-width: 48rem) {
    .home__section {
        margin-bottom: 4rem;
        padding-top: 8rem;
        margin-top: -8rem;
    }

    .home__section--over {
        z-index: var(--hero-section-level);
    }
}