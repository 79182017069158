.stepper-input__button {
    border-radius: 50%;
    width: 2em;
    height: 2em;
    background-color: var(--form-widget-color);
    border: .1rem solid var(--form-widget-border-color);
    cursor: pointer;
}

.person-count__select {
    width: 10ch;
    display: inline-block;
    margin-right: 1rem;
    vertical-align: middle;
}

.person-count__select > select {
    box-sizing: border-box;
}

.person-count__with-number {
    white-space: nowrap;
}