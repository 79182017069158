.success-message {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__text {
        padding: 2rem 2rem 3rem;
    }

    &__button {
        margin: 2rem 0 4rem;
    }
}