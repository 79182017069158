.unsuccessful-payment {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2em;
}

.unsuccessful-payment__image {
    background: url('../../../styles/failed_payment.svg') center no-repeat;
    height: 14em;
    width: 100%;
}

.unsuccessful-payment__info {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: .5em;
}

.unsuccessful-payment__without-payment {
    font-size: large;
    display: flex;
    flex-direction: column;
    align-items: center;
}