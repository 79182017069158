.about-us__title {
    font-size: var(--text-heading-size);
    font-weight: 700;
    margin-bottom: 2em;
    text-align: center;
}

.about-us__body {
    color: var(--light-text-color);
}

.about-us p {
    margin-bottom: 1.2em;
}
