.user-details {
    align-self: flex-start;
    width: 100%;
}

.user-details__title {
    font-size: var(--text-title-size);
	margin: 1rem 0; 
}

.user-details__name {
    display: inline-block;
}

.user-details__sub-container {
	margin: 3rem 0;
}

.user-details__buttons {
	margin: 2rem 0;
}

.user-details__link {
    margin: 1em 1em 0 0;
}

@media screen and (max-width: 24rem) {
    .user-details__buttons {
        display: flex;
        flex-wrap: wrap;
        margin-right: -1em;
    }

    .user-details__link {
        flex: 1 0 auto;
    }
}