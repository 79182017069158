.booking-step {
    background-color: var(--steps-bg-color);
    display: flex;
    flex-direction: column;
}

.booking-step--progress-bar-hidden .booking-step__body {
    border: 0;
    padding-left: 0;
    display: none;
}

.booking-step--progress-bar-hidden.booking-step--touched .booking-step__body {
    display: block;
}

.booking-step__module-wrapper {
    padding: 1rem 0;
}

.booking-step__body {
    min-height: 0.75rem;
    border-left: 3px solid var(--inactive-grey-color);
    margin: 1rem 0 1rem 1.6rem;
    padding-left: 3.3rem;
    display: flex;
    flex-direction: column;
}

.booking-step__value {
    margin: -0.5rem 0;
    color: var(--light-text-color);
}

@media screen and (max-width: 24rem) {
    .booking-step__body {
        padding-left: 1.5rem;
    }
}

.booking-step--touched .booking-step__body {
    border-color: var(--active-green-color);
}

.booking-step--full-width .booking-step__body--open {
    border-left: none;
    padding: 0;
    width: 95vw;
    max-width: 1500px;
    align-self: center;
}

.booking-step--last .booking-step__body {
    border-left: none;
}