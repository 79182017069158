.calendar-description {
    padding: 0 2.5rem 3rem 4rem;
    min-height: 0;
}

.calendar-description__details {
    color: var(--light-text-color);
    font-size: var(--text-default-size);
    font-weight: 700;
    line-height: 16px;
    margin-bottom: 3rem;
    text-align: center;
}

.calendar-description__body {
    max-height: calc(80vh - 24rem);
    min-height: 24rem;
    padding-right: 2rem;
}

.calendar-description__paragraph {
    margin-bottom: 1.2em;
}

.calendar-description__buttons {
    margin-top: 3rem;
    flex: 0 0 auto;
    display: flex;
    width: 100%;
    justify-content: center;
}

.calendar-description__button {
    flex: 0 1 15rem;
    margin: 0 .5rem;
}

.calendar-description__cancel {
    margin-right: 0;
}

.calendar-description .rcs-inner-container {
    max-height: calc(80vh - 24rem);
}

@media screen and (max-width: 37.5rem) {
    .calendar-description {
        padding: 0 1.5rem 2rem 2rem;
    }

    .calendar-description__details {
        margin-bottom: 2rem;
    }

    .calendar-description__body {
        padding-right: 1.5rem;
    }

    .calendar-description__buttons {
        margin-top: 2rem;
    }
}