.personal-details-form {
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
}

.personal-details-form__field {
    margin: 1rem 0;
}

.personal-details-form__validation {
    padding: 0 .2rem;
    position: relative;
    top: -1rem;
}