.modify-reservation {
    width: 100%;
}

.modify-reservation__title {
    font-size: var(--text-title-size);
    font-weight: 600;
    margin-right: 2rem;
    vertical-align: middle;
    display: inline;
}

.modify-reservation__heading {
    padding: 0 0 1.5rem;
}

.modify-reservation__buttons {
    margin-top: 4rem;
    display: flex;
    justify-content: flex-start;
}

.modify-reservation__save-button {
    margin-right: 1rem;
}

.modify-reservation__delete-button {
    margin-left: auto;
}

.modify-reservation__link {
    font-size: var(--text-title-size);
    margin-left: -2rem;
    cursor: pointer;
    color: var(--primary-select-color)
}

@media screen and (max-width: 48rem) {
    .modify-reservation__section {
        padding: 0;
    }

    .modify-reservation__sub-heading {
        padding: 1rem 0;
    }

    .modify-reservation__link {
        margin-left: 0;
    }
}

@media only screen and (-webkit-min-device-pixel-ratio: 2) and (max-device-width: 52rem), screen and (max-width: 35rem) {
    .modify-reservation__buttons {
        flex-direction: column;
    }

    .modify-reservation__save-button {
        margin-right: 0;
        margin-bottom: 1rem;
    }

    .modify-reservation__delete-button {
        margin-left: 0;
        margin-top: 1rem;
    }
}