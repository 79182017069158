.booking-step__step-value {
    display: inline;
    font-weight: 400;
    font-size: var(--text-default-size);
    margin-right: 1em;
}

.button.booking-step__step-btn {
    display: inline-block;
    margin: 0;
}


@media screen and (max-width: 48rem) {
    .button.booking-step__step-btn {
        display: block;
        margin: 1.5rem 0 0;
        width: 100%;
    }
}
