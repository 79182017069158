.invoice-details-form {
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
}

.invoice-details-form__field {
    margin: 1rem 0;
}

.invoice-details-form__validation {
    padding: 0 .2rem;
    position: relative;
    top: -1rem;
}

.invoice-details-info {
    border-left: 4px solid var(--primary-button-bg-color);
    padding: 0.25em 0.5em;
    margin-bottom: 0.25em;
    font-weight: 600;
}