@import "Event.scss";
@import "DayButtons.scss";
@import "Toolbar.scss";

.timetable {
    background: var(--container-bg-color);
    position: relative;
}

.timetable__select {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    z-index: 5;
    right: 0;
    top: -0.3rem;
    width: 14rem;
    padding-right: 0.5rem;

    @media screen and (max-width: 48rem) {
        position: static;
        margin: 0 0 1rem auto;
    }
}

.timetable__timezone-offset {
    position: absolute;
    width: 100%;   
    top: 2rem;
    text-align: center;
}

.fc-widget-header {
    margin-right: 0.4rem;
}

@supports (-ms-ime-align: auto) {
    /* Edge CSS styles go here */
    .fc-widget-header {
        margin-right: 17px;
    }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10+ CSS styles go here */
    .fc-widget-header {
        margin-right: 17px;
    }
}

.fc-event {
    border-radius: 0;
    border-width: 0;
    background-color: white;

    &:hover {
        background-color: white;
        color: var(--dark-text-color);
        z-index: 20 !important;
    }

    &-disabled {
        cursor: not-allowed;
    }
}

.fc-day-grid-event {
    min-height: 4rem;
    margin-bottom: 0.5rem;
}

.fc-event-disabled,
.fc-event-disabled:hover,
.fc-event-disabled .fc-time {
    border-color: #ccc;
    color: #ccc;
}

.fc-scroller {
    max-height: 57vh;
    overflow-y: scroll !important;
}

.fc-timeGridWeek-view,
.fc-dayGridMonth-view,
.fc-dayGridWeek-view,
.fc-timeGridDay-view {
    .fc-head-container.fc-head-container {
        border: none;
    }

    .fc-body > tr > td {
        border-bottom: none;
        border-right: none;
        border-left: none;
    }
}

.fc-dayGridDay-view {
    td {
        border: none;
    }
}

.fc-timeGridDay-view,
.fc-dayGridDay-view {
    .fc-time-grid .fc-slats td {
        height: 6rem;
    }

    .fc-head {
        display: none;
    }
}

.fc-timeGridWeek-view,
.fc-timeGridDay-view {
    .fc-time-grid .fc-slats td {
        height: 7rem;
    }

    .fc-axis {
        border-right: 1px solid transparent;
        vertical-align: top;
        padding: 0;
    }

    .fc-axis > span {
        background: white;
        padding: 0.5rem;
        position: relative;
        top: -1rem;
        right: 0.5rem;
        color: var(--light-text-color);
        font-size: 1rem;
    }

    tr:first-child > .fc-axis > span {
        display: none;
    }

    thead th:first-child {
        position: relative;
        top: 1rem;
        right: 1rem;
        background: white;
    }
}

.fc-past:not(.fc-day-header) {
    background-color: grey;
    opacity: 0.1;
}

.fc-time-grid .fc-now-indicator-line {
    border-color: var(--dark-text-color);
    z-index: 3;
}

.fc-time-grid .fc-now-indicator-line:before {
    content: "";
    width: 100%;
    background-color: grey;
    opacity: 0.1;
    height: 3000vh;
    position: absolute;
    bottom: 0;
}

.fc-time-grid .fc-now-indicator-line:after {
    content: "";
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background-color: var(--dark-text-color);
    position: absolute;
    top: -0.5rem;
    left: -0.5rem;
}

.fc-day.fc-day.fc-today {
    background: none;
}

.fc-now-indicator-arrow {
    display: none;
}

.timetable__event {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
}

.timetable__event--mobile {
    margin-bottom: 0.5rem;
    position: relative;
}

.fc-list-table {
    border-collapse: separate;
    border-spacing: 0.2rem;
}

.fc-list-heading.fc-list-heading td {
    background: none;
    border: none;
    font-weight: 700;
    padding: 1rem 0;
}

.fc-list-item.fc-list-item {
    td {
        border: none;
        background: none;
    }

    &:hover td {
        background: unset;
    }
}

.fc-day-header.fc-disabled-day {
    background: none;
}

.fc-time {
    width: 37.7188px
}

@media (max-width: 400px) {
    .timetable__select {
        width: 100%;
        margin-bottom: 30px;
    }

    .timetable__select .select {
        width: 100%;
    }
}