.calendar-item {
    display: grid;
    grid-template-rows: min-content auto min-content;
    grid-template-columns: min-content 1fr max-content;
    width: 100%;
    border-bottom: 1px solid var(--spacer-grey-color);
    padding: 1rem 0;
    grid-template-areas: "logo header button" "logo desc button"
}

.calendar-item__text {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.calendar-item__calendar-logo {
    margin-right: 2.5rem;
    border-radius: 3px;
    height: 5.5rem;
    width: 5.5rem;
    grid-area: logo;
}

.calendar-item__header {
    grid-area: header;
    align-self: center;
}

.calendar-item__first-free-slot {
    margin-top: 0.5rem;
}

.calendar-item__name {
    color: var(--dark-text-color);
    font-size: var(--text-heading-size);
    font-weight: 400;
    line-height: 1.8rem;
}

.calendar-item__first-free-slot-text {
    font-size: var(--text-default-size);
    line-height: 1.4rem;
    font-weight: 400;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
}

.calendar-item__first-free-slot-value {
    color: var(--light-text-color);
}

.button.calendar-item__select-button {
    margin: 0 2rem;
    grid-area: button;
    align-self: center;
    justify-self: end;
    width: auto;
    padding: 0.33em 2.33em;
    -webkit-tap-highlight-color: transparent;
}

.calendar-item__description {
    grid-area: desc;
}

.calendar-item__description-text {
    display: inline;
    margin-right: 0.5rem;
}

.calendar-item__more-description {
    margin-right: 0.5rem;
    cursor: pointer;
    text-decoration: underline;
}

@media screen and (max-width: 48rem) {
    .button.calendar-item__select-button {
        order: 1;
        flex: 1 0 100%;
        justify-self: stretch;
        margin: 1.5rem 0 0;
    }
    .calendar-item {
        grid-template-areas: "logo header header" "desc desc desc" "button button button"
    }
    .calendar-item__calendar-logo {
        margin-right: 2rem;
        border-radius: 3px;
    }
    .calendar-item__description {
        margin: 1rem 0 0;
    }
}