.data-policy {
    padding: 0 2.5rem 3rem 2.5rem;
}

.data-policy__body {
    height: 50vh;
    padding-right: 1rem;
}

.data-policy__body p {
    margin-bottom: 1.2em;
}

.data-policy__button {
    width: 100%;
    text-align: center;
    margin-top: 3rem;
}

@media screen and (max-width: 37.5rem) {
    .data-policy {
        padding: 1.5rem;
        padding-top: 0;
    }

    .data-policy__button {
        margin-top: 1.5rem;
    }
}