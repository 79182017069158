.hero-section {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.hero-section__background {
    background-color: var(--site-bg-color);
    width: 100%;
    min-height: 18rem;
    max-height: calc(100vh - 27rem);
    object-fit: cover;
    /* object-fit-polyfill hook */
    font-family: 'object-fit: cover;';
    border: 0.1rem solid #E7E7E7;
    padding: 0 2rem 2rem;
    position: relative;
    top: -0.1rem;
    box-sizing: content-box;
}

.hero-section__details {
    position: relative;
    background-color: #fff;
    min-height: 9rem;
    min-width: 9rem;
    box-sizing: content-box;
    margin-top: 3rem;
    overflow: hidden;
    text-align: center;
    z-index: var(--hero-section-level);
    top: 0;
}

.hero-section__details--has-background {
    border: 0.1rem solid #E7E7E7;
    margin-top: -8rem;
    padding: 2rem;
}

.hero-section__logo {
    height: 9rem;
    display: block;
}

.hero-section__description {
    width: 70%;
    text-align: center;
    padding: 3rem 3rem 0;
}

@media screen and (max-width: 48rem) {
    .hero-section__description {
        width: 100%;
        padding: 1.5rem 1.5rem 0;
    }

    .hero-section__details:not(.hero-section__details--has-background) {
        margin-top: 1.5rem;
    }

    .hero-section__background {
        max-height: 40vh;
    }
}