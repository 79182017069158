.event {
    padding: 0.5rem 0.2rem 0.5rem 1rem;
    box-shadow: 0.5rem 0 0 inset, 0 0 0 0.1rem #ddd inset;
    cursor: pointer;

    .fc-dayGridMonth-view &,
    .fc-dayGridWeek-view & {
        font-size: 0.9rem;
    }

    .fc-event-disabled & {
        background: #d7d7d7;
        opacity: .6;
    }

    %ellipsis {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    summary::marker {
        display: none;
        content: '';
    }

    summary::-webkit-details-marker {
        display: none;
        content: '';
    }

    &__summary {
        color: var(--light-text-color);
        display: grid;
        grid-template-rows: min-content auto;
        grid-template-columns: 1fr auto;
        grid-template-areas: "name name" "times seats";

        .event--open & {
            grid-template-areas: "name seats" "times times";
        }

        height: 100%;
    }

    &__name {
        font-size: 1.11em;
        color: var(--dark-text-color);
        font-weight: 700;
        @extend %ellipsis;
        grid-area: name
    }

    &__times {
        @extend %ellipsis;

        grid-area: times;
        height: 1.4em;
        overflow: hidden;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        align-self: start;

        &:before {
            content: "";
            height: 100%;
            display: block;
            flex: 0 1 0;
            padding: 0;
            width: 0;
        }

        .fc-event-disabled & {
            color: var(--dark-text-color);
        }
    }

    &__times-inner {
        flex: 0 0 auto;
    }

    &__seats {
        padding-right: 0.5rem;
        @extend %ellipsis;
        grid-area: seats;
        align-self: end;

        .fc-event-disabled & {
            color: var(--dark-text-color);
        }
    }

    &__count {
        border-radius: 0.2rem;
        padding: 0.2rem;
        margin-right: 1rem;
        font-family: inherit;
        color: inherit;
    }

    &__description {
        color: var(--light-text-color);
    }

    &__price {
        font-weight: bold;
    }

    &__actions {
        display: flex;
        justify-content: flex-end;
        color: var(--light-text-color);
        margin-right: 0.5rem;
        margin-top: 0.5rem;

        * {
            box-sizing: border-box;
        }
    }

    &__button {
        margin-left: 2rem;
    }
}
