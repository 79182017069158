.day {
    border-radius: 0.2rem;
    color: var(--dark-text-color);

    &--checked &__date {
        background: var(--primary-select-color);
        color: white;
    }

    &--disabled {
        opacity: 0.5;
    }

    &--button {
        padding: 1rem;
    }

    &--button:not(&--disabled) {
        cursor: pointer;
    }

    &--button:not(&--disabled):not(&--checked):hover &__date {
        background: #ddd;
        color: white;
    }

    &__week-day {
        color: var(--light-text-color);
        font-size: 1.3rem;
        font-weight: 400;
    }

    &__date {
        font-size: 1.5rem;
        font-weight: 400;
        line-height: 1.2;
        border-radius: 50%;
        $size: 1.5em;
        width: $size;
        height: $size;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
