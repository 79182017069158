.date-time-select__main {
    display: flex;
    margin-bottom: 2rem;
}

.date-time-select__time-picker {
    order: 2;
    max-width: 48.5rem;
    min-height: 25.4rem;
}

.date-time-select__main:before {
    content: '';
    display: block;
    border-left: 1px solid var(--spacer-grey-color);
    width: 0;
    margin: 4rem 4rem 0;
    order: 1;
}

.date-time-select__date-time {
    display: block;
    color: var(--dark-text-color);
    font-size: var(--text-default-size);
    font-weight: 700;
    line-height: 1.18em;
    padding: 1.3rem .5rem;
}

.chip {
    display: inline-block;
    position: relative;
    margin: 0 .5rem .5rem 0;
    text-align: center;
    height: var(--widget-height);
}

.chip-group {
    border: none;
    background: white;
    padding: .5rem;
}

.chip__label {
    appearance: none;
    font-family: inherit;
    background-color: transparent;
    border-radius: .2rem;
    border: .1rem solid var(--form-widget-border-color);
    padding: .5rem .7rem;
    color: var(--dark-text-color);
    font-size: var(--text-small-size);
    width: 11ch;
    text-align: center;
    position: relative;
    display: block;
    z-index: 2;
}

.chip__label--selected {
    background-color: var(--primary-select-color);
    color: #fff;
}

.chip--person-count {
    margin: 0 .5rem;
    padding: .5rem 1rem;
    width: auto;
    min-width: 9.5ch;
}

.date-time-select__person-count {
    margin-left: .5rem;
    display: flex;
    align-items: center;
    font-size: var(--text-default-size);
    margin-bottom: 1em;
}

.date-time-select__person-count-select {
    margin-right: 1rem;
    width: 5.4rem;
}

.rdtPicker td.rdtOld, .rdtPicker td.rdtNew {
    visibility: hidden;
}

.date-time-select__time-picker .rcs-custom-scroll {
    isolation: isolate;
}

.date-time-select__time-picker .rcs-custom-scroll .rcs-inner-container {
    background:
    linear-gradient(black 30%, #fff0),
    linear-gradient(#fff0, black 70%) 0 100%,
    linear-gradient(white, transparent),
    linear-gradient(transparent, white);
    background-repeat: no-repeat;
    background-size: 100% 7rem, 100% 7rem, 100% 4.4rem, 100% 4.4rem;
    background-position: top, bottom, top, bottom;
    background-attachment: local, local, scroll, scroll;
    background-blend-mode: multiply;
    height: 21rem;
    scroll-behavior: smooth;
}

.date-time-select__time-picker .rcs-custom-scroll .rcs-inner-container>div {
    mix-blend-mode: screen;
    min-height: 100%;
    background: white;
}

@media only screen and (max-device-width: 52rem) and (-webkit-min-device-pixel-ratio: 2), screen and (max-width: 35rem) {
    .date-time-select__main {
        flex-direction: column;
        align-items: center;
    }

    .date-time-select__person-count,
    .date-time-select__time-picker {
        text-align: center;
        width: 100%;
        justify-content: center;
    }

    .date-time-select__time-picker {
        min-height: auto;
    }

    .date-time-select__person-count {
        border-bottom: .1rem solid var(--spacer-grey-color);
        padding-bottom: 1.5rem;
        margin-left: 0;
    }

    .date-time-select__main:before {
        display: none;
    }

    .date-time-select__date-time {
        text-align: center;
        border: .1rem solid var(--spacer-grey-color);
        border-left: none;
        border-right: none;
        margin: 1.5rem 0;
        padding: 1rem 0;
    }

    .date-time-select__dropdown {
        margin: 0 auto;
    }
    
    .date-time-select__time-picker .rcs-custom-scroll .rcs-inner-handle {
        margin-left: 0.5rem;
    }
}

.date-time-select-step__button {
    -webkit-tap-highlight-color: transparent;
}