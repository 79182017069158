.password__title,
.password-form__field,
.password-info {
    margin: 1rem 0;
}

.password-form__field {
    display: block;
}

.password__title {
    font-size: var(--text-title-size);
}

.password__submit {
    width: 20rem;
}