.payment-processing {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2em;
}

.payment-processing__image {
    background: url('../../../styles/loading_icon.svg') center no-repeat;
    height: 14em;
    width: 100%;
}
