.popup-background {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
    background-color: #4A4A4A;
    opacity: 0.5;
    z-index: var(--content-above-level);
}

.popup-body {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 50%;
    left: 50%;
    width: 50%;
    max-height: 80vh;
    height: auto;
    background-color: #fff;
    border: 0.2rem solid #BAC7CC;
    border-radius: 0.3rem;
    z-index: var(--popup-level);
}

@media screen and (max-height: 25rem) {
    .popup-body {
        min-height: 20em;
    }
}

.popup-body__title-wrapper {
    position: relative;
    padding: 2.25rem 5rem 2rem;
}

.popup-body__title {
    color: var(--dark-text-color);
    font-size: var(--text-title-size);
    font-weight: 400;
    text-align: center;
}

.popup-body__close-icon {
    position: absolute;
    right: 1rem;
    top: 1rem;
    cursor: pointer;
    height: 4.5rem;
    width: 4.5rem;
    padding: .3rem;
    display: inline-block;
    font-size: 0;
    -webkit-appearance: none;
    background: none;
    border: .3rem solid transparent;
    border-radius: 50%;
    color: var(--light-text-color);
    transition: color 0.1s ease-in-out;
}

.popup-body__close-icon:focus {
    border: var(--focus-border);
    outline: none;
}


@media screen and (max-width: 75rem) {
    .popup-body {
        width: 60%;
    }
}

@media screen and (max-width: 48rem) {
    .popup-body {
        width: 90%;
    }

    .popup-body__title-wrapper {
        padding: 1.75rem 3rem 2rem;
    }

    .popup-body__title {
        font-size: var(--text-heading-size);
    }

    .popup-body__close-icon {
        width: 3.5rem;
        top: .5rem;
        right: .5rem;
    }

    .popup-body__close-icon .circle-close {
        width: 1.8rem;
        height: 1.8rem;
    }
}