.header {
    position: relative;
    z-index: var(--header-level);
    box-shadow: var(--box-shadow);
    background-color: #fff;
    width: 100%;
}

.header__nav-bar {
    position: relative;
    min-height: 6rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 0 1.3rem;
    z-index: var(--header-level);
    max-width: 1000px;
    margin: 0 auto;

    .header__navbar-toggle {
        margin-left: auto;
        border: 1px solid var(--navbar-toggle-color);
        border-radius: 0.25rem;
    }

    .header__navbar-toggle:focus {
        box-shadow: none
    }
}

.header__lateral {
    display: flex;
    flex: 1 0 40%;
    min-width: 0;
    align-items: center;
    flex-flow: wrap;
    height: 6rem;
}

.header__title {
    font-size: var(--text-default-size);
    color: var(--dark-text-color);
    font-weight: bold;
    text-decoration: none;
    cursor: pointer;
    margin-right: 3rem;
}

.header__app-view-switcher {
    width: 14rem;
}

.header__links {
    display: flex;
    flex: 1 1 0;
    flex-wrap: wrap;
    justify-content: flex-end;
    height: 100%;
    overflow: hidden;
    align-items: center;
}

.header__links::before {
    content: '';
    height: 100%;
    display: block;
    flex: 0 1 0;
    padding: 0;
    width: 0;
}

.header__link {
    display: block;
    font-size: 1.3rem;
    color: var(--dark-text-color);
    text-decoration: none;
    font-weight: 400;
    margin-right: 2em;
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
}

.header__link--mobile {
    display: none;
}

.header__link::after {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    padding: 1rem;
    position: absolute;
    left: -1rem;
    top: -1rem;
}

.header__menu {
    display: flex;
    align-items: center;
    justify-content: right;
    height: 100%;
    z-index: var(--header-logo-level);
    flex: 1 0 5%;
    min-width: 0;
}

.header__account {
    margin-left: 1.5rem;
    color: var(--light-text-color);
}

.header__button {
    appearance: none;
    background: none;
    border: none;
    font-family: inherit;
    cursor: pointer;
    display: block;
    color: var(--light-text-color);
}

.header__icon {
    border: .3rem solid transparent;
    border-radius: 50%;
    transform: scale(1, -1);
}

.header__button:hover {
    color: var(--dark-text-color);
    transition: color 0.1s ease-in-out;
}

.header__icon:focus {
    border: var(--focus-border);
    outline: none;
}

@media screen and (max-width: 48rem) {
    .header__menu {
        align-items: start;
        margin-top: 2rem;
    }

    .header__title { 
        margin-right: 0;
        flex-basis: 80%;
    }

    .header__title--hidden-mobile {
        display: none;
    }

    .header__link {
        flex-basis: 100%;
        margin: 0 0 2rem 0;
        font-size: var(--text-heading-size);
    }
        
    .header__link--mobile {
        display: initial;
    }

    .header__account {
        display: none;
    }
}