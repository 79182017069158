.cookie-consent {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    min-height: 8rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
    box-shadow: 0 -0.2rem 0.4rem 0 var(--box-shadow-color);
    background: var(--container-bg-color);
    z-index: 999;
}

.cookie-consent__content {
    flex: 2;
    padding: 1em;
    margin: 1em;
}

.cookie-consent__button-wrapper {
    flex: 1;
    margin: 1em 2em 1em;
    text-align: right;
}

.cookie-consent__button {
    font-size: var(--text-heading-size);
    padding: .5em 1em;
    height: auto;
}

.cookie-consent__button--decline {
    margin-right: 1em;
}

@media screen and (max-width: 48rem) {
    .cookie-consent {
        display: block;
    }

    .cookie-consent__content {
        padding: 0;
    }

    .cookie-consent__button-wrapper {
        display: flex;
        flex-direction: column-reverse;
        margin: 1em;
    }

    .cookie-consent__button {
        width: 100%;
    }

    .cookie-consent__button--decline {
        margin: 0;
        margin-top: 1em;
    }
}