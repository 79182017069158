.select select::-ms-expand {
    display: none;
}

.select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__year-dropdown-container--select {
    width: var(--text-input-width);
    max-width: 100%;
    background-color: #fff;
    padding: 0;
    outline: none;
    border-radius: 0.2rem;
    overflow: hidden;
    border: 0.1rem solid var(--form-widget-border-color);
    background: #fff url(../../../styles/dropdown-indicator.svg) no-repeat;
    background-position: right .5rem center;
    height: var(--widget-height);
}
    
.select > select,
select.react-datepicker__month-select,
select.react-datepicker__year-select {
    padding: .3rem;
    padding-left: 1rem;
    padding-right: 1.5rem;
    width: 100%;
    height: 100%;
    margin-bottom: 0;
    border: .1rem solid transparent;;
    box-shadow: none;
    background-color: transparent;
    background-image: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    font-size: var(--text-default-size);
    font-family: inherit;
}

.select:focus-within,
select.react-datepicker__month-select:focus,
select.react-datepicker__year-select:focus {
    border: .1rem solid var(--primary-select-color);
    box-shadow: 0 0 0 .1rem var(--primary-select-color);
    outline: none;
}

.select--timepicker {
    width: 8.4rem;
}

.react-datepicker__month-dropdown-container--select {
    width: 50%;
}

.react-datepicker__year-dropdown-container--select {
    width: 40%;
    float: left;
}

.select--with-icon {
    width: 3rem;
    height: 3rem;
    background: none;
    padding: 0;
    border: none;
    color: var(--light-text-color);
}

.select--with-icon:hover {
    color: var(--dark-text-color);
}

.select--with-icon > select {
	border: none;
    padding: 1.5rem;
    position: relative;
    top: -3.3rem;
}

.select--with-icon:focus-within {
    border: none;
    box-shadow: none;
}