.contact-info {
    text-align: center;
    font-style: normal;
    color: var(--dark-text-color);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    line-height: 1.23em;
    margin-bottom: -5rem;
}

.contact-info > * {
    margin-bottom: 5rem;
}

.contact-info__title {
    font-size: var(--text-heading-size);
    font-weight: 700;
    line-height: 1.8rem;
}

.contact-info__opening-hours {
    display: flex;
}

.contact-info__opening-hours > *:first-child {
    margin: 0 8rem 0 0;
}

.contacts {
    display: flex;
    justify-content: space-around;
    width: 100%;
}

.contacts--vertical {
    display: block;
}

.contacts--vertical .contacts__item:not(:last-child) {
    margin: 0 0 5rem 0;
}

.contacts__item {
    flex: 1 0 0;
    min-width: 0;
    display: flex;
    flex-direction: column;
    margin: 0 1rem;
    align-items: center;
}

.contact {
    color: var(--dark-text-color);
    text-decoration: none;
}

.contact:focus {
    outline: none;
    border: .3rem transparent;
}

.contact:focus > .contact__icon {
    border: var(--focus-border);
}

.contact__icon {
    width: 5.4rem;
    height: 5.4rem;
    margin: 0 auto 2rem;
    text-decoration: none;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: scale(1, -1);
    color: var(--primary-button-bg-color);
}

.contact:hover .contact__icon {
    color: var(--primary-button-bg-hover-color);
}