.overview-calendar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 5px;

    .fc-scroller {
        max-height: initial;
        overflow-y: scroll;
    }

    .fc-center h2 {
        margin-top: .2em;
    }

    .fc-resource-cell {
        word-break: break-word;
    }
}
