.pocket {    
    background: var(--placeholder-light-color);
    border: 2px solid var(--placeholder-light-color);
    text-align: start;
    margin: .5em .5em 0 .5em;
    padding: .5em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 38px;
}

.pocket--checked { 
    color: var(--dark-text-color);
    border: 2px solid var(--primary-select-color);
}

.pocket:not(.pocket--checked):hover {
    border: 2px solid var(--secondary-select-color);
}

.pocket__name {
    margin-left: .5em;
}

.payment-method__input {
    appearance: auto;
    position: absolute;
    width: 15px;
    height: 15px;
    top: 12px;
    filter: hue-rotate(-30deg);
}

.payment-method__label {
    position: unset;
}
