.customer-data-step {
    padding-left: .5rem;
}

.questions__instructions {
    margin-block-end: 2em;
    color: var(--light-text-color);
}

.questions__question {
    margin-bottom: 2rem;
}

.question__widget {
    margin: 1rem 0 .5rem 0;
}

.question__name {
    font-size: var(--text-default-size);
    display: block;
    margin-bottom: .5rem;
}

.question__required {
    color: var(--light-text-color);
    font-weight: 500;
}

.customer-data-step__button {
    -webkit-tap-highlight-color: transparent;
}