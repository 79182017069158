.payment-process-pending {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2em;
}

.payment-process-pending__image {
    background: url('../../../styles/still_processing_payment.svg') center no-repeat;
    height: 14em;
    width: 100%;
}

.payment-process-pending__description {
    width: 70%;
}