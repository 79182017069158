.button {
    font-family: inherit;
    font-size: var(--button-default-size);
    border-radius: 0.2rem;
    padding: 0.2em 1em;
    cursor: pointer;
    border: .1rem solid transparent;
    font-weight: 400;
    line-height: 1.4;
    transition: all 0.1s ease-in-out;
    text-decoration: none;
    height: var(--widget-height);
    text-align: center;
    white-space: nowrap;
    display: inline-block;
}