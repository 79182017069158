.menu {
    position: relative;
    color: var(--light-text-color);
}

.menu__menu {
    position: absolute;
    right: 0;
    background: white;
    border: 1px solid var(--form-widget-border-color);
    list-style-type: none;
    z-index: 20;
}

.menu__item {
    cursor: pointer;
    margin: 0.1rem 0;
    padding: 1rem 1.5rem;
    color: var(--light-text-color);
    width: 100%;
    text-align: left;
    font-size: inherit;
}

.menu__item:hover {    
    color: var(--dark-text-color);    
}

.profile-menu__item--bordered {
    border: unset;
    border-top: 1px solid var(--form-widget-border-color);
    padding-top: 1rem;
    margin-top: .5rem;
}

.profile-menu__link {
    text-decoration: none;
    -webkit-appearance: none;
    border: none;
    background: none;
    font-family: var(--font-family);
    display: block;
    width: 100%;
    text-align: left;
    cursor: pointer;
    color: inherit;
    white-space: nowrap;
}