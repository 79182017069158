.service-item {
    display: grid;
    grid-template-rows: min-content auto min-content;
    grid-template-columns: min-content 1fr max-content;
    width: 100%;
    border-bottom: 1px solid var(--spacer-grey-color);
    padding: 1rem 0;
    grid-template-areas: "logo header button" "logo desc button"
}

.service-item__text {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.service-item__service-logo {
    margin-right: 2.5rem;
    border-radius: 3px;
    height: 5.5rem;
    width: 5.5rem;
    grid-area: logo;
}

.service-item__header {
    grid-area: header;
    align-self: center;
}

.service-item__details {
    color: var(--light-text-color);
    font-size: var(--text-default-size);
    font-weight: 700;
    line-height: 16px;
    margin-top: 0.5rem;
}

.service-item__detail:not(:only-child):not(:last-child):after {
    content: "";
    width: 0;
    height: 1em;
    border-right: solid 0.07em var(--light-text-color);
    filter: opacity(0.5);
    display: inline-block;
    margin: 0 1.33em;
    position: relative;
    top: 0.06em;
}

.service-item__name {
    font-size: var(--text-heading-size);
    font-weight: 400;
    line-height: 1.8rem;
}

.button.service-item__select-button {
    margin: 0 2rem;
    grid-area: button;
    align-self: center;
    justify-self: end;
    width: auto;
    padding: 0.33em 2.33em;
    -webkit-tap-highlight-color: transparent;
}

.service-item__description {
    grid-area: desc;
}

.service-item__description-text {
    display: inline;
    margin-right: 0.5rem;
}

.service-item__more-description {
    margin-right: 0.5rem;
    cursor: pointer;
    text-decoration: underline;
}

.service-item__price {
    white-space: nowrap;
}

@media screen and (max-width: 48rem) {

    .button.service-item__select-button {
        order: 1;
        flex: 1 0 100%;
        justify-self: stretch;
        margin: 1.5rem 0 0;
    }

    .service-item {
        grid-template-areas: "logo header header" "desc desc desc" "button button button"
    }

    .service-item__service-logo {
        margin-right: 2rem;
        border-radius: 3px;
    }

    .service-item__description {
        margin: 1.5rem 0 0;
    }
}

@media screen and (max-width: 35rem) {

    .service-item__detail:not(:only-child):first-child:after{
        margin: 0 .5em;
    }
}

@media screen and (max-width: 25rem) {

    .service-item__details {
        display: flex;
        flex-direction: column;
    }
}
