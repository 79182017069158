.reservations {
    width: 100%;
}

.reservations__title {
    margin-bottom: 1.5rem;
    font-size: var( --text-title-size);
}

.reservations__section {
    margin: 3rem 0;
}

.reservations__empty {
    height: 7.2rem;
    text-align: center;
}

.reservations__call-to-action {
    margin: 1rem auto;
}

.reservation-preview {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: var(--spacer-grey-color) .1rem solid;
    padding: 1rem;
}

.reservation-preview__date {
    font-weight: 400;
    line-height: 1.6rem;
    margin-bottom: 0.23em;
}

.reservation-preview__service {
    color: var(--light-text-color);
    font-weight: 400;
    line-height: 1.6rem;
    margin-bottom: 0.23em;
}

.reservation-preview__calendar {
    color: var(--light-text-color);
    font-weight: 400;
    line-height: 1.6rem;
}

.reservation-preview__buttons--with-status {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-end;
}

.reservation-preview__status {
    margin-bottom: .5rem;
}

.reservation-preview__button--delete {
    margin-right: .5rem;
    flex: none;
}

.reservation-preview__more {
    color: var(--dark-text-color);
}

.reservation-preview__pricetag {
    margin-left: auto;
    margin-right: 5%;
}

.reservation-preview__price {
    font-size: larger;
}

@media screen and (max-width: 48rem) {

    .reservation-preview__buttons {
        display: flex;
        flex-direction: column;
        flex-flow: column-reverse;
        justify-content: space-around;
    }

    .reservation-preview__buttons--with-status {
        flex-flow: column;
    }
    
    .reservation-preview__button {
        flex: 1 0 50%;
    }

    .reservation-preview__button--delete {
        margin-right: 0;
    }

    .reservation-preview__button--modify {
        margin-bottom: .5rem;
    }

    .reservation-preview__price {
        font-size: medium;
    }
}

@media screen and (max-width: 30rem) {

    .reservation-preview {
        display: flex;
        flex-wrap: wrap;
    }

    .reservation-preview__buttons {
        flex-direction: row;
        flex-flow: row;
        margin-top: 1rem;
        width: 100%;
    }

    .reservation-preview__buttons--with-status {
        flex-direction: column;
        align-items: stretch;
    }

    .reservation-preview__button--delete {
        margin-right: .5rem;
    }

    .reservation-preview__button--modify {
        margin-bottom: 0;
    }

    .reservation-preview__body {
        flex-basis: 50%;
    }

    .reservation-preview__pricetag {
        margin-right: 0;
    }
}

@media screen and (max-width: 15rem) {

    .reservation-preview__buttons {
        align-self: stretch;
    }

    .reservation-preview__body {
        flex-direction: row;
        margin-bottom: .5rem;
        flex-basis: 0;
    }

    .reservation-preview__text {
        flex: 1 0 auto;
    }
}