.paginator {
    display: flex;
    justify-content: space-between;
}

.paginator > * {
    margin-bottom: 1rem;
}

.paginator__button {
    padding: 0.23em 0.33em;
}

@media screen and (max-width: 35rem) {
    .paginator {
        flex-direction: column;
        font-size: var(--text-heading-size);
    }

    .paginator__button {
        padding: 0.23em 0.63em;
    }
}