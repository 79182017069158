.login {
    display: flex;
    flex-direction: column;
    color: var(--dark-text-color);
    width: 100%;
}

.login--popup {
    justify-content: flex-start;
    align-items: center;
    padding: 0 3rem 3rem 3rem;
    overflow-y: auto;
}

.login__title {
    font-size: var(--text-title-size);
}

.login__signup-message {
    margin: 2rem 0;
    font-weight: 400;
}

.login__fields {
    margin-bottom: 1rem;
}

.login--popup .login__fields {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login > * {
    flex: none;
}

.login__field,
.login__error,
.login__button {
    margin: 1rem 0;
}

.login__forgot-password {
    margin-bottom: 2rem;
}