@import '../Button/Button.css';

.button--secondary {
    background-color: transparent;
    border: .1rem solid currentColor;
    color: var(--primary-button-bg-color);
}

.button--secondary:hover {
    background-color: transparent;
    color: var(--primary-button-bg-hover-color);
}

.button--secondary-dangerous {
    background-color: transparent;
    border: .1rem solid currentColor;
    color: var(--dangerous-color);
}

.button--secondary-dangerous:hover {
    color: var(--dangerous-hover-color);
}