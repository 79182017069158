.button--segment {
    font-size: inherit;
}

.button--segment-center {
    border-radius: 0;
    border-left-width: 0;
}

.button--segment-left {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.button--segment-right {
    border-left-width: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.button--segment-alone {
    border-radius: .2rem;
    border-left-width: .1rem;
}

.span--segment-collapsed {
    color: var(--primary-button-bg-color);
    margin: 0 5px;
}