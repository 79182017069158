.readonly {
    padding-bottom: 2rem;

    &--modify {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 3rem 2rem 5rem;
    }

    &--booking-step {
        max-width: 51.2em;
        margin: 0 auto;
    }

    &__info {
        padding-right: .5em;
    }

    &__phone-text {
        font-weight: 600;
    }

    &__phone {
        margin-top: 1rem;

        .readonly--modify & {
            margin-top: 3rem;
        }
    }

    &__phone-link {
        margin-left: 1rem;
        padding: .3rem 1rem;
    }
}