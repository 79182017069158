.reservation-confirm {
    width: 100%;
}

.reservation-confirm__section {
    margin-top: 3rem;
}

.reservation-confirm__title,
.reservation-confirm__section,
.reservation-confirm__email {
    text-align: center;
}

.reservation-confirm__company-name {
    font-weight: bold;
}

.reservation-confirm__failed-warning {
    color: var(--dangerous-color)
}

.reservation-confirm__email {
    margin-top: 5rem;
}

.reservation-confirm__email-address,
.reservation-confirm__success-text {
    margin-top: 1rem;
}

.reservation-confirm__failed-reservations {
    margin-top: -1rem;
    border-left: 0.2rem solid var(--dangerous-color);
}

.reservation-confirm__subtitle {
    font-size: var(--text-heading-size);
    border-bottom: var(--spacer-grey-color) .1rem solid;
    padding-bottom: 0.5em;
    margin-top: 3rem;
}

@media screen and (max-width: 48rem) {
    .reservation-confirm__failed-reservations > .reservation-preview {
        padding-left: 1rem;
    }
}