@import '../Button/Button.css';
.button--primary {
    background-color: var(--primary-button-bg-color);
    color: var(--primary-button-text-color);
}

.button--primary:hover {
    background-color: var(--primary-button-bg-hover-color);
}

.button--primary-dangerous {
    color: var(--primary-button-text-color);
    background-color: var(--dangerous-color);
}

.button--primary-dangerous:hover {
    background-color: var(--dangerous-hover-color);
}