.phone-input {
    width: var(--text-input-width);
    max-width: 100%;
    display: flex;
    align-items: baseline;
}

.phone-input__countrycode.phone-input__countrycode {
	width: 7.5rem;
    margin-right: .5rem;
    flex: 0 0 auto;
}

.phone-input__number {
	width: 100%;
}

.phone-input__label.text-input__label--shrunk {
    transform: translate(-7.7rem, -1.3rem) scale(0.75)
}

.phone-input__countrycode-label {
    width: 1px;
    height: 1px;
    position: absolute;
    opacity: 0;
    overflow: hidden;
}