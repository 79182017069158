.radio {
    border: none;
}

.radio__button {
    position: relative;
}

.radio__input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    appearance: none;
    border: none;
}

.radio__input::-ms-check {
    display: none;
}

.radio__label {
    appearance: none;
    font-family: inherit;
    text-align: center;
    position: relative;
    display: block;
    z-index: 2;
    cursor: pointer;
}