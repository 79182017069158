
.reservation-end__add-new-reservation {
    margin: 1rem 0;
}

.reservation-end {
    margin: 3rem 0 0 5.5rem;
}

.reservation-end__subtitle {
    color: var(--dark-text-color);
    font-size: var(--text-heading-size);
    font-weight: 700;
    line-height: 18px;
    border-bottom: var(--spacer-grey-color) .1rem solid;
    padding-bottom: 0.5em;
}

.reservation-end__reservations {
    margin-bottom: 5rem;
}

.reservation-end__form {
    max-width: var(--text-input-width);
}

.reservation-end__user,
.reservation-end__statement {
    margin: 2rem 0;
}

.reservation-end__user-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.reservation-end__password {
    margin: 1rem auto;
}

.reservation-end__password-validation {
    padding: 0 .2rem;
    position: relative;
    top: -1rem;
}

.reservation-end-separator {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 2rem;
}

.reservation-end-separator__line {
    width: 1px;
    height: 5rem;
    background: var(--spacer-grey-color);
    justify-self: center;
}

.reservation-end-separator__text {
    padding: 1rem 0;
    background-color: var(--container-bg-color);
    color: var(--light-text-color);
}

.reservation-end__send-button {
    width: var(--text-input-width);
}

.reservation-end__container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.reservation-end__subtotal {
    margin: 1rem 0.5em;
    font-size: larger;
    margin-left: auto;
}

@media screen and (max-width: 48rem) {
    .reservation-end {
        margin-left: 0;
    }

    .reservation-end__reservations,
    .reservation-end__user {
        margin-bottom: 2rem;
    }
    
    .reservation-end__user-details {
        flex-direction: column;
        align-items: start;
    }

    .reservation-end-separator {
        display: block;
        width: 100%;
        text-align: center;
        border-bottom: .1rem solid var(--spacer-grey-color);
        line-height: .1rem;
        margin: 2rem 0 3rem;
    }
    
    .reservation-end-separator__line {
        display: none;
    }

    .reservation-end-separator__text {
        padding: 0 1rem;
    }

    .reservation-end__add-new-reservation,
    .reservation-end-login,
    .reservation-end__send-button {
        width: var(--text-input-width);
    }

    .reservation-end__container {
        display: flex;
        flex-direction: column-reverse;
        align-items: unset;
    }

    .reservation-end__subtotal {
        align-self: flex-end;
        margin-top: .5em;
    }
}

@media screen and (max-width: 35rem) {
    .reservation-end {
        margin: 3rem 0 0 1rem;
    }
}

@media screen and (max-width: 30rem) {
    .reservation-end__add-new-reservation,
    .reservation-end-login,
    .reservation-end__send-button,
    .reservation-end__form {
        width: 100%;
        white-space: normal;
        height: fit-content;
    }
}