.text-input {
    position: relative;
    width: var(--text-input-width);
    max-width: 100%;
}

.text-input__input {
    border: 0.1rem solid var(--form-widget-border-color);
    border-radius: 0.2rem;
    height: var(--widget-height);
    width: 100%;
    outline: none;
    font-size: var(--text-default-size);
    padding: 0.3rem;
    color: var(--dark-text-color);
    font-family: inherit;
    resize: none;
    /* hide inner shadow on Safari */
    -webkit-background-clip: padding-box;
}

.text-input::placeholder {
    color: var(--light-text-color);
    opacity: 0.8;
}

.text-input--multi-line {
    resize: auto;
    height: 5em;
    min-height: 2.3em;
    min-width: var(--text-input-width);
}

.text-input__input:focus {
    border: .1rem solid var(--primary-select-color);
    box-shadow: 0 0 0 .1rem var(--primary-select-color);
    outline: none;
}

.text-input__label {
    font-size: inherit;
    font-weight: 400;
    z-index: 1;
    transform: translate(.8rem, 0.6rem) scale(1);
    pointer-events: none;
    transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    top: 0;
    left: 0;
    position: absolute;
    display: block;
    transform-origin: top left;
    color: var(--light-text-color)
}

.text-input__label--shrunk {
    transform: translate(0.2rem, -1.3rem) scale(0.75)
}