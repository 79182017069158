.confirmation {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 4rem;
}

.confirmation__header {
    color: var(--dark-text-color);
    font-size: var(--text-title-size);
    font-weight: 400;
    line-height: 1.2em;
    margin-bottom: 1rem;
}

.confirmation__description {
    color: var(--light-text-color);
    font-size: var(--text-default-size);
    font-weight: 400;
    line-height: 1.6em;
    margin-bottom: 2rem;
}

.confirmation__warning {
    color: var(--dangerous-color);
}

.confirmation__buttons {
    flex: 0 0 auto;
    display: flex;
    width: 100%;
    justify-content: center;
}

.confirmation__button {
    flex: 0 1 15rem;
    margin: 0 .5rem;
}

@media screen and (max-width: 37.5rem) {
    .confirmation {
        padding-bottom: 2rem;
    }
}